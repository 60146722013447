import {
  ASSIGNED,
  BLOCKED,
  COMPLETED,
  CUSTOMER,
  FINISHED,
  HOLIDAY,
  IMAGE,
  REQUESTED,
  SOCKET_NAMESPACE,
  SOCKET_NAMESPACE_NOTIFY,
  SOCKET_URI,
  TEXT,
  UPLOAD_SERVER_URI,
  YOUR_IP_ADDRESS_IS_BLOCKED,
} from '@constants';
import { Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Manager } from 'socket.io-client';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import ChatBubble from '@components/ChatBubble';
import icon_mini_chat_back from '@images/icon_mini_chat_back.png';
import icon_mini_chat_close from '@images/icon_mini_chat_close.png';
import icon_mini_chat_rating_color from '@images/icon_mini_chat_rating_color.png';
import icon_mini_chat_rating_gray from '@images/icon_mini_chat_rating_gray.png';
import icon_send from '@images/icon_send.png';
import icon_send_disabled from '@images/icon_send_disabled.png';
import typing from '@images/typing.png';
import { commonDateFormat, consoleLogDev, turnOnAlarm } from '@utils';
import LoadingSpinner from '@components/LottieSpinner';
import moment from 'moment';
import DialogButton from '@components/DialogButton';
import CloseIcon from '@mui/icons-material/Close';
import { addChatCustomer, getMessageListCustomer, updateServiceRequestCustomer } from '@apis';
import { gtmFinishMiniChat, gtmRateMiniChat } from '@gtm';

const MiniChatRoom = (props) => {
  const {
    isMessageListLoading,
    setIsMessageListLoading,
    requestId,
    dealerInfo,
    setDealerInfo,
    handleCloseClick,
    requestStatus,
    setRequestStatus,
    isOffline,
    startTime,
    endTime,
    includeBreakStartTime,
    includeBreakEndTime,
    isIncludeBreak,
    handleBackClick,
    messages,
    setMessages,
  } = props;
  const [socketInstance, setSocketInstance] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [message, setMessage] = useState();
  const [imageFileUri, setImageFileUri] = useState();
  const [imageFile, setImageFile] = useState();
  const [imageMessage, setImageMessage] = useState();
  const [sending, setSending] = useState(false);
  const scrollViewRef = useRef();
  const [imageDialogOpen, setImageDialogOpen] = useState();
  const { t } = useTranslation();
  const [qualityRating, setQualityRating] = useState(0);
  const [satisfactionRating, setSatisfactionRating] = useState(0);
  const currentDate = moment().format('DD.MM.YYYY');
  const socket = useRef(null);
  // const textFieldRef = useRef(null);
  const textFieldRef = useRef();
  // const [isMessageListLoading, setIsMessageListLoading] = useState(false);

  useEffect(() => {
    consoleLogDev('MiniChat Room: requestStatus', requestStatus);
  }, [requestStatus]);

  const handleQualityRatingChange = (rating) => {
    setQualityRating(rating);
  };

  const handleSatisfactionRatingChange = (rating) => {
    setSatisfactionRating(rating);
  };

  const handleSubmit = () => {
    updateServiceRequest();
  };

  const handleKeyPress = (e) => {
    e.preventDefault();
    //it triggers by pressing the enter key
    if (e.key === 'Enter') {
      if (message && message.trim() !== '') {
        handleSendButton();
      }
    }
  };

  const fileBuffer = async (file) => {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      const readFile = function (event) {
        const buffer = reader.result;
        resolve(buffer);
      };

      reader.addEventListener('load', readFile);
      reader.readAsArrayBuffer(file);
    });
  };

  // const requestMessageListCustomer = async () => {
  //   try {
  //     setIsMessageListLoading(true);
  //     const response = await getMessageListCustomer(requestId);

  //     if (response) {
  //       const messageList = response?.data;
  //       setMessages(response?.data);
  //       return messageList;
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   } finally {
  //     setIsMessageListLoading(false);
  //   }
  // };
  const requestMessageListCustomer = useCallback(async () => {
    try {
      // setIsMessageListLoading(true);
      const response = await getMessageListCustomer(requestId);

      if (response) {
        const messageList = response?.data;
        return messageList;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsMessageListLoading(false);
    }
  }, [requestId]);

  useEffect(() => {
    const fetchData = async () => {
      const messageList = await requestMessageListCustomer();
      if (messageList) {
        // console.log('🚀 ~ requestMessageListCustomer ~ messageList:', messageList);
        setMessages(messageList);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const manager = new Manager(SOCKET_URI, { transports: ['websocket'] });
    const _socket = manager.socket(SOCKET_NAMESPACE);

    if (message !== undefined && message !== '') {
      _socket.emit('checkChat', { roomId: requestId, sender: 'CUSTOMER', isTyping: true });
    } else {
      _socket.emit('checkChat', { roomId: requestId, sender: 'CUSTOMER', isTyping: false });
    }
  }, [message]);

  useEffect(() => {
    requestMessageListCustomer();
  }, [requestId, messages, requestStatus]); //messages,

  const sendMessage = async ({ message, imageMessage, socket }) => {
    if (!message && !imageMessage) return;

    const dto = {
      requestId,
      writer: CUSTOMER,
      template: '',
      requestStatus,
      dealerCode: dealerInfo?.dealerCode,
      messageContents: message || imageMessage,
      messageType: imageMessage ? IMAGE : TEXT,
    };

    try {
      const response = await addChatCustomer(dto);
      // console.log('🚀 ~ sendMessage ~ response:', response);
      if (response?.message === YOUR_IP_ADDRESS_IS_BLOCKED) {
        setIsDialogOpened(true);
        return;
      }
      socket.current.emit('chat', {
        roomId: requestId,
        sender: CUSTOMER,
        msg: response?.data,
        messageType: imageMessage ? IMAGE : TEXT,
      });
      if (requestStatus === ASSIGNED) {
        socket.current.emit('notify', { roomId: requestId, sender: CUSTOMER });
      }

      setMessage('');
      setImageMessage('');
      setImageFileUri(null);
      setImageFile(null);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadImage = async (imageFile) => {
    const buffer = await fileBuffer(imageFile);
    const filename = Date.now() + '--' + imageFile.name;

    try {
      let image = '';
      const response = await axios.put(
        UPLOAD_SERVER_URI +
          `${filename}?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2032-07-12T07:39:57Z&st=2022-07-12T07:39:57Z&spr=https&sig=VTaYoQM0gl0vfYyY%2BFAZZ9XcgNmYtSThoF8fCuFG6o0%3D`,
        buffer,
        {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
          },
        },
      );

      if (response.status === 201) image = UPLOAD_SERVER_URI + filename;
      consoleLogDev('upload image', image);
      return image;
    } catch (e) {}
  };

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    const fileTypeImage = file?.type.includes('image');

    const fileReader = new FileReader();
    fileReader.addEventListener(
      'load',
      function () {
        setImageFileUri(this.result);
      },
      false,
    );

    if (file && fileTypeImage) {
      fileReader.readAsDataURL(file);
    } else if (file && !fileTypeImage) {
      return alert('Invalid File Type. Please select an image file only.');
    } else {
      setImageFileUri(null);
    }
  };

  const handleSendButton = useCallback(async () => {
    setSending(true);
    consoleLogDev('handleSendButton', imageFile);
    try {
      let image;
      if (imageFile) {
        image = await uploadImage(imageFile);
        consoleLogDev(IMAGE, image);
      }
      await sendMessage({ message: message, imageMessage: image, socket });
      consoleLogDev('sent');
    } catch (e) {
    } finally {
      setSending(false);
    }
  }, [imageFile, message, socket]);

  useEffect(() => {
    consoleLogDev('sending', sending);
    if (!sending) {
      textFieldRef?.current?.focus();
    }
  }, [sending]);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    socketInitializer(requestId);
    document.addEventListener('click', play);

    function play() {
      socket.current.emit('read', { sender: 'CUSTOMER' });
      // console.log('🚀 ~ play ~ sender:');
    }

    return () => {
      document.removeEventListener('click', play);
      socket.current.disconnect(); // Disconnect socket on component unmount
    };
  }, []);

  //** Socket Initializer
  const socketInitializer = (requestId) => {
    const manager = new Manager(SOCKET_URI, { transports: ['websocket'] });
    // const socket = manager.socket(SOCKET_NAMESPACE);
    const socket_notify = manager.socket(SOCKET_NAMESPACE_NOTIFY);
    const _socket = manager.socket(SOCKET_NAMESPACE);

    // const socket_notify = new Manager(SOCKET_URI, { transports: ['websocket'] }).socket(SOCKET_NAMESPACE_NOTIFY);

    _socket.on('connect', () => {
      consoleLogDev(`join, { roomId: ${requestId}' }`);
      _socket.emit('join', { roomId: requestId, sender: CUSTOMER });
    });

    _socket.on('chat', (msg) => {
      // console.log('🚀 ~ _socket.on ~ msg123123123:', msg);
      setMessages((currentMsg) => [
        ...currentMsg,
        {
          ...msg.msg,
        },
      ]);
      // setMessages((prev) => prev.map((chat) => ({ ...chat, customerRead: true })));
      _socket.emit('read', { roomId: requestId, sender: 'CUSTOMER' });
    });

    _socket.on('checkChat', async ({ isTyping, roomId, isDownload, sender, ...args }) => {
      if (sender !== 'CUSTOMER') {
        if (isTyping && roomId == requestId) setIsTyping(true);
        else setIsTyping(false);
      }
    });

    _socket.on('read', async ({ sender }) => {
      setMessages((prev) => prev.map((chat) => ({ ...chat, customerRead: true })));
      if (sender != 'CUSTOMER') {
        setMessages((prev) => prev.map((chat) => ({ ...chat, dealerRead: true })));
        // await getMessageListCustomer(requestId);
      }
    });

    _socket.on('assign', async ({ roomId, sender }) => {
      consoleLogDev(`assign, { roomId: ${roomId}, sender: ${sender}`);
      turnOnAlarm();
      try {
        const response = await getMessageListCustomer(requestId);
        if (response && requestStatus !== BLOCKED) {
          setRequestStatus(ASSIGNED);
          setMessages(response?.data);

          for (const item of response.data) {
            if (item.template === ASSIGNED) {
              setDealerInfo(item.systemMessageArgs[0]);
              break;
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    });

    socket_notify.on('connect', () => {
      consoleLogDev(`join, { roomId: ${requestId}' }`);
      socket_notify.emit('join', { roomId: requestId, sender: CUSTOMER });
    });

    socket_notify.on('notify', () => {
      turnOnAlarm();
    });

    socket_notify.on('leave', async ({ blockYn }) => {
      consoleLogDev('socket_notify.on ~ leave ~ blockYn:', blockYn);
      try {
        await onEndChat(blockYn);
      } catch (error) {
        console.error(error);
      }
    });

    socket_notify.on('block', () => {
      consoleLogDev('mini chat room ~ socket_notify.on ~ block');
      setRequestStatus(BLOCKED);
      turnOnAlarm();
    });

    socket.current = _socket;
    return _socket;
  };

  const onEndChat = async (blockYn) => {
    try {
      if (!blockYn) {
        const requestData = {
          requestId,
          requestStatus: COMPLETED,
        };
        const response = await updateServiceRequestCustomer(requestData);
        if (response) {
          setRequestStatus(response?.data?.serviceRequest?.requestStatus);
          gtmFinishMiniChat();
          turnOnAlarm();
        }
      } else {
        setRequestStatus(BLOCKED);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateServiceRequest = async () => {
    const rating = {
      connection: qualityRating,
      satisfaction: satisfactionRating,
    };
    const requestData = {
      requestId,
      requestStatus: FINISHED,
      rating,
    };
    setIsLoading(true);
    try {
      const response = await updateServiceRequestCustomer(requestData);
      if (response && requestStatus !== BLOCKED) {
        setRequestStatus(response?.data?.serviceRequest?.requestStatus);
        setIsLoading(false);
        gtmRateMiniChat(qualityRating, satisfactionRating);
      }
      turnOnAlarm();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const adjustStyle = () => {
      const maxRows = 3;
      if (textFieldRef.current) {
        const rows = textFieldRef.current.value.split('\n').length;
        if (rows > maxRows) {
          textFieldRef.current.style.padding = '12px 0 0 20px';
        } else {
          textFieldRef.current.style.padding = '12px 20px 0';
        }
      }
    };
    adjustStyle();
  }, [message]);

  return (
    <Box sx={styles.container}>
      {isDialogOpened && <Box sx={styles.overlay} />}

      {isDialogOpened && (
        <Box sx={styles.dialogBox} onClick={(e) => e.stopPropagation()}>
          <Typography sx={styles.dialogTitle}>{t('4530_chat_common.new_chat_unavailable')}</Typography>
          <Typography sx={styles.dialogDescription}>{t('4530_chat_common.it_is_currently_not_possible_to_start_a_new_chat')}</Typography>

          <Box sx={styles.buttonBox}>
            {/* button색깔 수정 필요 */}
            <DialogButton onClick={handleBackClick} variant="outlined" color="primary_blue" size="small">
              <Typography sx={styles.dialogButtonText}>{t('0000_common.close')}</Typography>
            </DialogButton>
          </Box>
        </Box>
      )}

      <Box sx={styles.header}>
        <Box sx={{ display: 'flex' }}>
          <Button sx={styles.headerBackButton} onClick={handleBackClick}>
            <img style={{ width: '24px' }} src={icon_mini_chat_back} alt="icon_mini_chat_back" />
          </Button>
          <Typography style={styles.headerTitle}>{t('4530_chat_common.hyundai_assistant')}</Typography>
        </Box>
        <Button sx={styles.headerCloseButton} onClick={handleCloseClick}>
          <img style={{ width: '16px' }} src={icon_mini_chat_close} alt="icon_mini_chat_close" />
        </Button>
      </Box>

      {/* loading 필요 */}
      <Box sx={styles.chatWrapper} ref={scrollViewRef}>
        {isMessageListLoading && (
          <Box sx={styles.spinnerBox}>
            <LoadingSpinner />
          </Box>
        )}
        {isOffline && (
          <Box sx={styles.chatContainer}>
            <Box sx={styles.chatDateBox}>
              <Typography sx={styles.chatBubbleDate}>{currentDate}</Typography>
            </Box>

            <Box sx={styles.systemMessage}>
              <Typography sx={{ ...styles.systemText, letterSpacing: '0' }}>
                {t('4511_chat_unavailable.this_service_is_currently_unavailable')}
              </Typography>
              <Typography sx={styles.systemText}>{t('4511_chat_unavailable.we_be_back_soon')}</Typography>
              <Typography sx={styles.systemText}>{t('4511_chat_unavailable.thank_you_for_your_patience')}</Typography>
              {isIncludeBreak ? (
                <Typography sx={styles.systemText}>
                  {t('4511_chat_unavailable.chat_available_time')} {startTime}-{endTime} {includeBreakStartTime}-{includeBreakEndTime}
                </Typography>
              ) : (
                <Typography sx={styles.systemText}>
                  {' '}
                  {t('4511_chat_unavailable.chat_available_time')}: {startTime} - {endTime}
                </Typography>
              )}
            </Box>
          </Box>
        )}

        {/* 추후 수정 */}
        {/* {isOffline && requestStatus === HOLIDAY && (
          <Box sx={styles.chatContainer}>
            <Box sx={styles.chatDateBox}>
              <Typography sx={styles.chatBubbleDate}>{currentDate}</Typography>
            </Box>

            <Box sx={styles.systemMessage}>
              <Typography sx={styles.systemText}>
                {t('4511_chat_unavailable.this_service_is_currently_unavailable_for_the_public_holiday')}
              </Typography>
            </Box>
          </Box>
        )} */}

        {requestStatus && requestStatus !== HOLIDAY && (
          <Box ref={scrollViewRef} flex={1} sx={styles.chatContainer}>
            {messages?.map((msg, i) => {
              let isFirstItem = true;

              if (i > 0) {
                isFirstItem = !(commonDateFormat(messages[i - 1].createDate) === commonDateFormat(msg.createDate));
              }
              return (
                <ChatBubble
                  key={i}
                  msg={msg}
                  dealerInfo={dealerInfo}
                  isFirstItem={isFirstItem}
                  isOffline={isOffline}
                  imageDialogOpen={imageDialogOpen}
                  setImageDialogOpen={setImageDialogOpen}
                  onImageLoadEnd={(e) => {
                    if (scrollViewRef.current) {
                      scrollViewRef.current.scrollTop = scrollViewRef.current.scrollHeight;
                    }
                  }}
                />
              );
            })}
            {isTyping ? (
              <>
                <Box
                  sx={{
                    // overflowWrap: 'break-word',
                    height: '32px',
                    width: '70px',
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: '#F6F3F2',
                      height: '32px',
                      width: '70px',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ position: 'relative' }}>
                      <img width={28} height={8} src={typing} />
                    </Box>
                  </Typography>
                </Box>
              </>
            ) : (
              <></>
            )}
            {requestStatus === FINISHED ? (
              <Box sx={styles.submitted}>
                <Typography style={styles.ratingTitle}>{t('4520_chat_ratings.thank_you_for_sharing_your_experience')}</Typography>
              </Box>
            ) : requestStatus === COMPLETED ? (
              <Box sx={styles.ratingContainer}>
                {isLoading && (
                  <Box sx={styles.spinnerBox}>
                    <LoadingSpinner />
                  </Box>
                )}
                <>
                  <Box sx={styles.ratingBox}>
                    <Typography style={styles.ratingTitle}>{t('4520_chat_ratings.rate_your_experience')}</Typography>
                  </Box>

                  <Box sx={styles.divider} />
                  <Box sx={styles.ratingBox}>
                    <Typography style={styles.ratingSubtitle}>{t('4520_chat_ratings.how_was_the_quality_of_the_connection')}</Typography>
                    <RatingComponent rating={qualityRating} onRatingChange={handleQualityRatingChange} />
                  </Box>
                  <Box sx={styles.divider} />
                  <Box sx={styles.ratingBox}>
                    <Typography style={styles.ratingSubtitle}>{t('4520_chat_ratings.how_satisfied_are_you_with_the_consultation')}</Typography>
                    <RatingComponent rating={satisfactionRating} onRatingChange={handleSatisfactionRatingChange} />
                  </Box>
                  <Box sx={styles.submitWrapper}>
                    <Button onClick={handleSubmit} sx={styles.submitButton} disabled={qualityRating === 0 || satisfactionRating === 0}>
                      {t('0000_common.submit')}
                    </Button>
                  </Box>
                </>
                {/* )} */}
              </Box>
            ) : null}
          </Box>
        )}
      </Box>

      {(requestStatus === REQUESTED || requestStatus === ASSIGNED) && (
        <Box sx={styles.chatInput} className={requestStatus === REQUESTED || requestStatus === ASSIGNED ? '' : 'chat-input-disabled'}>
          <Box sx={styles.chatInputContainer}>
            {imageFileUri ? (
              <Box sx={{ position: 'relative', display: 'flex', padding: '12px 20px' }}>
                <img src={imageFileUri} alt="imageFileUri" style={{ width: '150px', height: 'auto', objectFit: 'contain', objectPosition: '0 0' }} />
                {sending ? (
                  <Typography sx={styles.uploading}>{t('0000_common.uploading')}</Typography>
                ) : (
                  <Button disableRipple sx={styles.fileRemove} onClick={() => setImageFileUri(null)}>
                    <CloseIcon />
                  </Button>
                )}
              </Box>
            ) : (
              <TextField
                id="chat-input"
                value={message}
                disabled={sending}
                multiline
                maxRows={3}
                placeholder={t('0500_live_consult.please_enter_a_chat')}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  } else return true;
                }}
                onKeyUp={handleKeyPress}
                inputRef={textFieldRef}
                focused={!sending}
                autoFocus
                sx={styles.chatInputfield}
              />
            )}

            <Box sx={styles.chatToolBox}>
              {/* <Button sx={styles.button} component="label" htmlFor="upload-img" disableRipple>
                <img src={icon_clip} style={styles.chatFileIcon} alt="icon_clip" />
                <input onChange={handleChangeFile} accept="image/*" hidden type="file" id="upload-img" />
              </Button> */}

              <Button disabled={sending ? sending : !imageFileUri && !message} sx={styles.button} onClick={handleSendButton}>
                {message && message.trim() !== '' ? (
                  <img style={styles.chatSendIcon} src={icon_send} alt={icon_send} />
                ) : (
                  <img style={styles.chatSendIcon} src={icon_send_disabled} alt={icon_send_disabled} />
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MiniChatRoom;

const RatingComponent = ({ rating, onRatingChange }) => {
  const handleStarClick = (starIndex) => {
    onRatingChange(starIndex);
  };
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {[1, 2, 3, 4, 5].map((starIndex) => (
        <Typography key={starIndex} onClick={() => handleStarClick(starIndex)} style={{ cursor: 'pointer' }}>
          {starIndex <= rating ? (
            <img style={{ width: '24px' }} src={icon_mini_chat_rating_color} alt="blue_star" />
          ) : (
            <img style={{ width: '24px' }} src={icon_mini_chat_rating_gray} alt="gray_star" />
          )}
        </Typography>
      ))}
    </Box>
  );
};
